.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*

NEW STYLES

*/


*,
*:before,
*:after {
	box-sizing: inherit;
}

html {
	--grey: #e7e7e7;
	--gray: var(--grey);
	--blue: #0072b9;
	--pink: #d60087;
	--yellow: #ffc600;
	--black: #2e2e2e;
	--red: #c73737;
	--green: #61e846;
	--text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.2);
	--box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.2);
	font-size: 62.5%;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	box-sizing: border-box;
}

html,
body {
	background-color: #d0d0d0;
}

body {
	display: grid;
	grid-template-rows: 1fr;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
}

input:not([type='checkbox']):not([type='radio']),
textarea,
select {
	display: block;
	padding: 1rem;
	border: 1px solid var(--grey);
}

.app {
	max-width: 800px;
	background: white;
}

.app form {
	border: 0;
	display: grid;
	grid-template-columns: auto 1fr;
	grid-auto-rows: 1fr;
	font-size: 3rem;
	text-align: center;
	font-weight: 600;
	padding: 2rem;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	grid-gap: 2rem;
	align-items: stretch;
}

.app :is(button, input, select, textarea) {
	margin: 0;
	text-align: center;
	font-weight: 600;
	border: 0;
}

.app :is(button, input, select, textarea):focus {
	outline-color: violet;
}

.app input {
	width: 250px;
	text-align: center;
}

.app select {
	background: none;
	border: 0;
	padding: 2rem;
}

.app form > * {
	background: var(--yellow);
	margin: 0;
	border-radius: 0;
	-webkit-appearance: none;
	display: grid;
	align-content: center;
}

@media (max-width: 700px) {
	.app {
		margin: 1.5em;
	}

	.app form {
		display: grid;
		grid-template-columns: 1fr;
		grid-auto-rows: 1fr;
	}

	.app input {
		width: 100%;
	}
}
